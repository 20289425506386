import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { HeaderBackButton } from "../../../components/BackButton";
import { CustomButton } from "../../../components/CustomButton";
import { Icon, IconNameEnum } from "../../../components/Icon";
import { YearSelect } from "../../../components/SelectComponent";
import { useGetTranslatedEnumResourceCodeGetter } from "../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import {
  COLLECTION_POINT_PAGE_PATH,
  CONTAINER_DETAIL_PAGE_PATH,
  INVENTORY_SHEET_PAGE_PATH,
} from "../../../middleware/routes";
import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { getWeightHistoryContainer } from "../../../selectors/weightHistory";
import { EnumName } from "../../../utils/enums";

import styles from "./WeightHistoryPageHeaderComponent.module.scss";

export const WeightHistoryPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { containerType, rfid, wasteType, disableTime } = useSelector(getWeightHistoryContainer);
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();
  const containerTypeTitle = getTranslatedEnumResourceCode(EnumName.ContainerTypes, containerType, "");

  const { name, locationId } = useSelector(getCollectionPointDetail);
  const wasteTypeTitle = getTranslatedEnumResourceCode(EnumName.WasteTypes, wasteType);

  const getHeaderTitle = () => {
    let title = "";
    if (containerTypeTitle && wasteType !== 7) {
      title += `${containerTypeTitle} `;
    }
    title += `${wasteTypeTitle} | ${rfid}`;

    return title;
  };

  const getBackTo = () => {
    const queryParams = new URLSearchParams(location.search);
    const prevPageValue = queryParams.get("prev-page");

    return prevPageValue === INVENTORY_SHEET_PAGE_PATH.replace("/", "")
      ? INVENTORY_SHEET_PAGE_PATH
      : `${COLLECTION_POINT_PAGE_PATH}?id=${locationId}`;
  };

  const goToContainerDetail = () => {
    const queryParams = new URLSearchParams(location.search);
    const cid = queryParams.get("cid");
    const id = queryParams.get("id");
    const prevPage = queryParams.get("prev-page");

    return `${CONTAINER_DETAIL_PAGE_PATH}?cid=${cid}&id=${id}${prevPage ? `&prev-page=${prevPage}` : ""}`;
  };

  return (
    <div className={styles.container}>
      <HeaderBackButton backTo={getBackTo()} title={getHeaderTitle()} subtitle={name} />
      <div className={styles.controlsWrapper}>
        <YearSelect />
        {containerType !== 99 && disableTime && (
          <Link to={goToContainerDetail()} className={styles.editButton}>
            <CustomButton
              text={t("containerDetailPage.editContainer")}
              startIcon={<Icon name={IconNameEnum.pencil} />}
            />
          </Link>
        )}
      </div>
    </div>
  );
};
